export const contractTableConf = [
	{
		label: '合同名称',
		prop: 'contract_name',
	},
	{
		label: '商家名称',
		prop: 'dusername',
		search: true,
		type: 'input'
	},
	{
		label: '店铺名称',
		prop: 'nick',
		search: true,
		type: 'input'
	},
	{
		label: '合同类型',
		prop: 'typeInfo',
		search: true,
		type: 'select',
		options: [
			{
				label: '单品合同',
				value: 1
			},
			{
				label: '年度合同',
				value: 2
			},
		]
	},
	{
		label: '合同状态',
		prop: 'statusInfo',
	},
	{
		label: '起效时间',
		prop: 'createtime'
	},
	{
		label: '直播日期',
		prop: 'zbtime'
	},
	{
		label: '创建人',
		prop: 'eusername'
	},
	{
		label: '操作',
		prop: 'operation'
	},
]



export const loansTableConf = [
	{
		label: '直播日期',
		prop: 'zbtime',
		search: true,
		type: 'daterange'
	},
	{
		label: '商品图片',
		prop: 'pict_url',
	},
	{
		label: '商品名称',
		prop: 'title',
		search: true,
		type: 'input'
	},
	{
		label: '商家名称',
		prop: 'dusername',
		search: true,
		type: 'input'
	},
	{
		label: '店铺名称',
		prop: 'nick',
		search: true,
		type: 'input'
	},
	{
		label: '贷款类型',
		prop: 'typeInfo',
		search: true,
		type: 'select',
		options: [
			{
				label: '保证金',
				value: 1
			},
			{
				label: '服务费',
				value: 2
			},
			{
				label: '佣金',
				value: 3
			},
		]
	},
	{
		label: '贷款状态',
		prop: 'statusInfo',
	},
	{
		label: '应收金额(元)',
		prop: 'money'
	},
	{
		label: '实收金额(元)',
		prop: 'money',
	},
	{
		label: '欠款金额(元)',
		prop: 'money'
	},
	{
		label: '招商人员',
		prop: 'eusername'
	},
	{
		label: '操作',
		prop: 'operation'
	},
]


export const liveCountCleaningTableConf = [
	{
		label: '直播日期',
		prop: 'account6'
	},
	{
		label: '截止时间',
		prop: 'account7',
	},
	{
		label: '直播主题',
		prop: 'account8'
	},
	{
		label: '主播名称',
		prop: 'account9'
	},
	{
		label: '负责人',
		prop: 'account10'
	},
	{
		label: '操作',
		prop: 'account10'
	},
]