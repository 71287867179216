<template>
	<div class="contract">
		<e-table ref="tables" v-on:resetSearch="resetSearch" @query-Table-data="handleCurrentChange" v-on:searchParamsChange="searchParamsChange" :total="total" :currentPage="currentPage" :condition="form" :tableCols="contractTableConf" :showOperation="true" :dataOrigin="dataOrigin">
			<template #customOperation>
				<el-col :span="24">
					<el-radio-group @change="agreeChange" v-model="tabPosition" style="margin-bottom: 30px;">
						<el-radio-button label="dqy">商家未签约</el-radio-button>
						<el-radio-button label="dsh">待审核</el-radio-button>
						<el-radio-button label="yqy">已完成</el-radio-button>
						<el-radio-button label="shbtg">审核不通过</el-radio-button>
					</el-radio-group>
				</el-col>
			</template>
			<template #operation="scope">
				<div></div>
				<div class="cur_p" @click="tg(scope.rowData.id)">审核通过</div>
				<div class="cur_p"  @click="th(scope.rowData.id)">一键驳回</div>
			</template>
		</e-table>
		<e-dialog title="" ref="dialogRef">
		</e-dialog>
	</div>
</template>

<script>
import {contractTableConf} from '@data/finance'
export default {
	name: 'Contract',
	data() {
		return {
			contractTableConf,
			dataOrigin: {
				data: [
				]
			},
			tabPosition: 'dqy',
			form: {},
			activeName: "first",
			currentPage: 1,
			pageSize: 10,
			total: 0,
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetContractLists()
		},
		tg(id){
			let that = this

			this.$confirm('是否通过?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.contract.service.sh',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '操作成功!'
					});
					that.$refs.tables.loading = true
					that.GetContractLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		th(id){
			let that = this

			this.$confirm('是否驳回?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.contract.service.sh&method=back',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '操作成功!'
					});
					that.$refs.tables.loading = true
					that.GetContractLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		agreeChange(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetContractLists()
		},
		resetSearch(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetContractLists()
		},
		searchParamsChange(e) {
			this.$refs.tables.loading = true
			this.condition = e.condition
			this.currentPage = 1
			this.GetContractLists()
		},
		handleClick() {},
		GetContractLists(){
			let that = this
			this.dataOrigin.data = []
			let formData = this.$refs.tables.condition
			that.$refs.tables && that.$refs.tables.initData()
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=live.contract.service.lists.get',{
				type: this.tabPosition,
				page:this.currentPage,
				zbtime: formData.zbtime,
				shop_name: formData.nick,
				typeInfo: formData.typeInfo,
				sname: formData.sname,
				query:formData.contract_name}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				// that.dataOrigin.data = response.data.data
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			this.GetContractLists()
		});
	}
}
</script>